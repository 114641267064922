.panel {
    max-width: 486px;
    padding: 48x;
    margin: 0 auto;
}
.site {
    background: #f4f4f4;
}
@media screen and (min-width: 486px) {
    .site-panel .content_main {
        padding: 24px;
    }
}

@media screen and (min-width: 486px) {
    .site-panel .panel {
        border: 1px solid #e9e9e9;
        border-radius: 3px;
    }
}

.site-panel .panel {
    background: #fff;
    margin: 0 auto;
    max-width: 486px;
    padding: 48px;
}
.site-panel hr {
    border: 1px solid #f4f4f4;
}
.site-panel .actions_wrap {
    margin: 0 auto;
    max-width: 486px;
    padding-left: 24px;
    padding-right: 24px;
}
.site-panel .content_actions {
    padding-bottom: 12px;
    padding-top: 12px;
    text-align: center;
}
@media screen and (min-width: 980px) {
    .site-panel .content_actions {
        position: static!important;
    }
}

.form-group .label, .form_unit .label {
    display: inline-block;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
}

body {
    background: #fff;
}

html {
    box-sizing: border-box;
}

[label-required]:after {
    color: #d13239;
    content: attr(label-required);
    padding-left: 4px;
}

::selection {
    background: #bad3f8;
    text-shadow: none;
}

.form_unit .btn-select:not(.input-inline), .form_unit .input-text:not(.input-inline), .form_unit .input-textarea:not(.input-inline), .form_unit .select-wrap:not(.input-inline) {
    display: block;
}

input:required {
    box-shadow: none;
}

.input-text, .input-textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #868686;
    border-radius: 2px;
    height: auto;
    outline: 0;
    padding: 5px 8px 6px;
    width: 100%;
}

input {
    line-height: normal;
}

button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

body {
    background: #fff;
}

.form-group, .form_unit {
    display: block;
    margin: 24px 0;
}

.footer_container {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(204, 204, 204);
    padding-left: 24px;
    padding-right: 24px;
    font-size: 10px;
    color: rgb(102, 102, 102);
    overflow: hidden;
}

footer {
    display: block;
}

.content_main {
    flex: 1 1;
    min-width: 0;
}

.content_wrap {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 0;
    width: 100%;
}

.site_content {
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    width: 100%;
    flex-direction: column!important;
}

.site_wrap {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    min-height: 100vh;
}

.error-msg {
    /* color: red; */
    color: #ef0000;
}